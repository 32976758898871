import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="main-footer">
      <div
        className="footer_bg-image"
        style={{
          backgroundImage: "url(assets/images/background/footer-bg.jpg), url(../assets/images/background/footer-bg.jpg)",
        }}
      />
      <div className="auto-container">
        <div className="inner-container">
          {/* Widgets Section */}
          <div className="widgets-section">
            <div className="row clearfix">
              {/* Big Column */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* Footer Column */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget logo-widget">
                      <div className="footer-logo">
                        <Link to="/" style={{ display: "flex" }}>
                          {/* <img src="assets/images/footer-logo.svg" alt="" /> */}
                          <img src="assets/images/logo-n-1.png" alt="" />
                          <h3
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontFamily: "lexend",
                            }}
                          >
                            Masjid
                          </h3>
                        </Link>
                      </div>
                      {/* Footer List */}
                      {/* <div className="footer_list">
                          <li>
                            58 Howard Street #2 cairo. <br /> CA 941
                          </li>
                          <li>(+88)311-2121101</li>
                        </div> */}
                      {/* Social Box */}
                      <div className="footer_socials">
                        <a href="https://facebook.com/">
                          <i className="fa-brands fa-facebook-f" />
                        </a>
                        <a href="https://twitter.com/">
                          <i className="fa-brands fa-twitter" />
                        </a>
                        <a href="https://youtube.com/">
                          <i className="fa-brands fa-youtube" />
                        </a>
                        <a href="https://instagram.com/">
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Footer Column */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="footer-title">Links</h4>
                      <ul className="footer-list">
                        <li>
                          <a href="#">About us</a>
                        </li>
                        <li>
                          <a href="#">Scholars</a>
                        </li>
                        <li>
                          <a href="#">Courses</a>
                        </li>
                        <li>
                          <a href="#">Latest News</a>
                        </li>
                        <li>
                          <a href="#">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Big Column */}
              <div className="big-column col-lg-6 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* Footer Column */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="footer-title">Other Link</h4>
                      <ul className="footer-list">
                        <li>
                          <a href="#">Online Courses</a>
                        </li>
                        <li>
                          <a href="#">Audio Listening</a>
                        </li>
                        {/* <li>
                            <a href="#">Sehri &amp; Iftar</a>
                          </li> */}
                        <li>
                          <a href="#">Our Events</a>
                        </li>
                        <li>
                          <a href="#">Quran Hifz Classes</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Footer Column */}
                  <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-widget newsletter-widget">
                      <h4 className="footer-title">newsletter</h4>
                      <div className="footer-text">
                        Waiting for your message is not your important time
                      </div>
                      <div className="newsletter-box">
                        <form method="post" action="">
                          <div className="form-group">
                            <input
                              type="email"
                              name="search-field"
                              placeholder="email address"
                              required
                            />
                            <button
                              type="button"
                              className="theme-btn fa-regular fa-paper-plane fa-fw"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div
          className="footer_bottom-bg"
          style={{
            backgroundImage: "url(assets/images/background/footer-bg_2.jpg)",
          }}
        />
        <div className="auto-container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="copyright">
              All rights reserved 2023 © Indian-Masjid
            </div>
            <ul className="footer-nav">
              <li>
                <a href="#">Terms of use</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
