import React, { useEffect, useState } from "react";
import Header from "../../../components/layouts/header/Header";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "./jamaathDetail.css";
import { Button, Dropdown, Space, message, Select } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import Footer from "../../../components/layouts/footer/Footer";

function JamaathDetail() {
  const { id } = useParams();

  const [jamaath, setJamaath] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [members, setMembers] = useState<any>();


  useEffect(() => {
    const fetchJamaathById = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/jamaaths/${id}`
        );
        setJamaath(response?.data?.data?.jamaath);
        setMembers(response?.data?.data?.jamaath?.committeeMembers)
        // setImageUrl('${process.env.REACT_APP_STORAGE_BASEURL}/' + response?.data?.data?.scholar?.photo)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchJamaathById();
  }, []);

  const handleButtonClick = (e: any) => {
    message.info("Click on left button.");
    console.log("click left button", e);
  };

  const handleMenuClick = (e: any) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 200 }, (_, index) => currentYear - index);

  const items = years.map((x,i) => ({
    label: x,
    value: x
  }))

  const fetchMembersByYears = async (values: string[]) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/jamaaths/get-committee-members`
      , {
        jamaathId: id,
        years: values
      });
      setMembers(response?.data?.data?.committeeMembers);
      // setImageUrl('${process.env.REACT_APP_STORAGE_BASEURL}/' + response?.data?.data?.scholar?.photo)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    fetchMembersByYears(value)
  };
  //  [
  //   {
  //     label: "2024",
  //     key: "1",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "2023",
  //     key: "2",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "2022",
  //     key: "3",
  //     icon: <UserOutlined />,
  //     danger: true,
  //   },
  //   {
  //     label: "2021",
  //     key: "4",
  //     icon: <UserOutlined />,
  //     danger: true,
  //     disabled: true,
  //   },
  // ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  

  

  return (
    <div className="page-wrapper">
      <Header />
      <section className="event-detail" style={{ paddingTop: "0" }}>
        <div className="auto-container">
          <div className="event-detail_image">
            <Swiper
              // navigation
              // spaceBetween={50}
              // slidesPerView={1}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper: any) => console.log(swiper)}

              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper: any) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {jamaath?.images?.length > 0 &&
                jamaath?.images?.map((photo: string) => (
                  <SwiperSlide>
                    <img
                      src={photo}
                      alt=""
                      style={{ height: "24rem" }}
                      onError={(e: any) => {
                        e.target.src = "assets/images/resource/course-5.jpg"; // Fallback image source
                        e.target.alt = "Image Error"; // Alt text for the fallback image
                      }}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            {/* <img
              style={{ height: "22rem" }}
              src={`${process.env.REACT_APP_STORAGE_BASEURL}/${jamaath?.images[0]}`}
              alt=""
            /> */}
          </div>
          <div className="row clearfix">
            <div className="column col-lg-8 col-md-12 col-sm-12">
              {/* <div className="event-detail_time">10:00 pm - 11:45 pm</div> */}
              <div className="event-detail_location">
                <i className="fa-solid fa-location-dot fa-fw" />{" "}
                {jamaath?.address}
              </div>
              <h3 className="event-detail_heading">
                <a href="event-detail.html">{jamaath?.name}</a>
              </h3>
              <div className="d-flex align-items-center flex-wrap">
                <div className="event-detail_price d-flex align-items-center">
                  -------- <span>---</span>
                </div>
                <a className="theme-btn ticket-btn" href="#">
                  --------
                </a>
              </div>
              <h4 className="event-detail_subtitle">About</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
              <ul className="event-detail_list">
                {jamaath?.studyClass && <li>Study Class</li>}
                {jamaath?.isAnyInstitutionUnderConstruction && (
                  <li>Institution Under Construction</li>
                )}
              </ul>
              {/* <p>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea the commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum lorem ipsum dolor sit amet.
              </p> */}
              <h4 className="event-detail_subtitle">Masjids</h4>
              {/* Accordian */}
              <div className="event-detail_accordian">
                {/* Accordion Box */}
                <div>
                  {jamaath?.masjids?.map((masjid: any, i: number) => (
                    <div className="jamaath-masjid-list-item">
                      <img
                        className="jamaath-masjid-list-item-img"
                        src={masjid.thumbnail}
                      />

                      <div className="jamaath-masjid-list-item-details-container">
                        <h6 style={{ color: "darkgreen" }}>{masjid.name}</h6>
                        <div className="jamaath-masjid-list-item-detail">
                        {masjid.description}
                        </div>
                        <button className="jamaath-masjid-list-item-button">
                          VIEW
                        </button>
                      </div>
                    </div>
                  ))}

                  {!jamaath?.masjids ||
                    (!jamaath?.masjids.length && (
                      <p style={{ textAlign: "center" }}>No masjids..!</p>
                    ))}
                </div>
                {/* <ul className="accordion-box_two">ff</ul> */}
              </div>
            </div>
            <div className="column col-lg-4 col-md-12 col-sm-12">
              <div className="organiser-widget">
                <h4 className="organiser-title">Details</h4>
                <ul className="organiser-info_list">
                  <li>
                    <span>State :</span>
                    {jamaath?.state}
                  </li>
                  <li>
                    <span>District :</span>
                    {jamaath?.district}
                  </li>
                  <li>
                    <span>Locality :</span>
                    {jamaath?.locality}
                  </li>
                  <li>
                    <span>Phone :</span>
                    {jamaath?.state}
                  </li>
                </ul>
                <div className="image">
                  <img src="assets/images/resource/organiser.jpg" alt="" />
                </div>
              </div>

              <div className="etn-widget etn-event-organizers">
                <h4 className="etn-widget-title etn-title">Organizers</h4>

                {/* {jamaath?.committeeMembers?.map(
                  (member: any, index: number) => (
                    <div className="etn-organaizer-item">
                      <div className="etn-organizer-logo">
                        <img
                          width={84}
                          height={84}
                          src={`${member.image}`}
                          alt=""
                          className="attachment-full size-full"
                          decoding="async"
                        />
                      </div>
                      <h4 className="etn-organizer-name">{member.name}</h4>
                      <h5 className="etn-organizer-name">
                        {member.designation}
                      </h5>
                      <div className="etn-organizer-email">
                        <span className="etn-label-name">Phone : </span>
                        <a href="">{member.mobile}</a>
                      </div>
                      <div className="etn-social">
                        <span className="etn-label-name">Social :</span>
                        <a
                          href="https://facebook.com/"
                          target="_blank"
                          className="etn-etn-icon fa-brands fa-facebook-f"
                          title="Facebook"
                        ></a>
                        <a
                          href="https://facebook.com/"
                          target="_blank"
                          className="etn-etn-icon fa-brands fa-twitter"
                          title="Twitter"
                        ></a>
                        <a
                          href="https://linkedin.com/"
                          target="_blank"
                          className="etn-etn-icon fa-brands fa-linkedin-in"
                          title="Linkedin"
                        ></a>
                      </div>
                    </div>
                  )
                )} */}
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="column col-lg-12 col-md-12 col-sm-12">
              {/* Members */}
              <div className="event-detail_speakers">
                <h4
                  className="event-detail_subtitle"
                  style={{ display: "inline-block" }}
                >
                  Committee Members
                </h4>
                {/* <Dropdown menu={menuProps}>
                  <Button style={{float: 'inline-end'}}>
                    <Space>
                      Year
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown> */}

                <Select
                  mode="multiple"
                  allowClear
                  style={{ float: 'inline-end', minWidth: '7rem' }}
                  placeholder="Year"
                  onChange={handleChange}
                  options={items}
                />

                <div className="row clearfix">
                  {/* Speaker Block One */}

                  {members?.map((member: any, i: number) => (
                    <div
                      className="speaker-block_one col-lg-3 col-md-4 col-sm-6"
                      key={i}
                    >
                      <div className="speaker-block_one-inner">
                        <div className="speaker-block_one-image">
                          <img
                            src={`${member.image}`}
                            alt=""
                            style={{
                              // width: "237px",
                              height: "200px",
                            }}
                          />
                        </div>
                        <div className="speaker-block_one-content">
                          {/* Social Box */}

                          <h6 className="speaker-block_one-heading">
                            <a href="">{member.name}</a>
                          </h6>
                          <h6 style={{ fontSize: "15px", color: "cadetblue" }}>
                            {member.designation}
                          </h6>
                          <p>Ph: {member.mobile}</p>

                          {/* <div className="speaker-block_one-title">Speaker</div> */}
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
                  {!members?.length && <p style={{textAlign: 'center'}}>No members!</p>}
              </div>

              {/* Scholars */}
              <div className="event-detail_speakers">
                <h4 className="event-detail_subtitle">Scholars</h4>
                <div className="row clearfix">
                  {/* Speaker Block One */}

                  {jamaath?.scholars?.map((sch: any, i: number) => (
                    <div
                      className="speaker-block_one col-lg-3 col-md-4 col-sm-6"
                      key={i}
                    >
                      <div className="speaker-block_one-inner">
                        <div className="speaker-block_one-image">
                          <img
                            src={`${process.env.REACT_APP_STORAGE_BASEURL}/${sch.photo}`}
                            alt=""
                            style={{
                              // width: "237px",
                              height: "200px",
                            }}
                          />
                        </div>
                        <div className="speaker-block_one-content">
                          {/* Social Box */}
                          <div className="speaker-block_one-socials">
                            <a href="https://facebook.com/">
                              <i className="fa-brands fa-facebook-f" />
                            </a>
                            <a href="https://instagram.com/">
                              <i className="fa-brands fa-instagram" />
                            </a>
                            <a href="https://twitter.com/">
                              <i className="fa-brands fa-twitter" />
                            </a>
                          </div>
                          <h6 className="speaker-block_one-heading">
                            <a href="scholar-detail.html">{sch.name}</a>
                          </h6>
                          {/* <div className="speaker-block_one-title">Speaker</div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer/>
    </div>
  );
}

export default JamaathDetail;
