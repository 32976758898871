import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/messaging'; // Import the messaging module

import Home from './pages/home/Home';
import MosquesList from './pages/mosques-list/MosquesList';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import Inaugurations from './pages/events/Inaugurations';
import JamaathDetail from './pages/jamaath/jamaath-detail/JamaathDetail';
import MasjidDetail from './pages/mosques-list/MosqueDetail';
import App from './App';

// import firebaseConfig from './firebase/firebaseConfig';

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/mosques",
    element: <MosquesList/>,
  },
  {
    path: "/inaugurations",
    element: <Inaugurations/>,
  },
  {
    path: "/jamaath/:id",
    element: <JamaathDetail/>,
  },
  {
    path: "/masjid/:id",
    element: <MasjidDetail/>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
     <App/>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
