import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "./MosqueList.css";
import { Button, Dropdown, Space, message, Select } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import Footer from "../../components/layouts/footer/Footer";

function MasjidDetail() {
  const { id } = useParams();

  const [masjid, setMasjid] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [members, setMembers] = useState<any>();

  useEffect(() => {
    const fetchMasjidById = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/mosques/${id}`
        );
        setMasjid(response?.data?.data?.masjid);
        // setMembers(response?.data?.data?.jamaath?.committeeMembers)
        // setImageUrl('${process.env.REACT_APP_STORAGE_BASEURL}/' + response?.data?.data?.scholar?.photo)
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMasjidById();
  }, []);

  const handleButtonClick = (e: any) => {
    message.info("Click on left button.");
    console.log("click left button", e);
  };

  const handleMenuClick = (e: any) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 200 }, (_, index) => currentYear - index);

  const items = years.map((x, i) => ({
    label: x,
    value: x,
  }));

  const fetchMembersByYears = async (values: string[]) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/jamaaths/get-committee-members`,
        {
          jamaathId: id,
          years: values,
        }
      );
      setMembers(response?.data?.data?.committeeMembers);
      // setImageUrl('${process.env.REACT_APP_STORAGE_BASEURL}/' + response?.data?.data?.scholar?.photo)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    fetchMembersByYears(value);
  };
  //  [
  //   {
  //     label: "2024",
  //     key: "1",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "2023",
  //     key: "2",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "2022",
  //     key: "3",
  //     icon: <UserOutlined />,
  //     danger: true,
  //   },
  //   {
  //     label: "2021",
  //     key: "4",
  //     icon: <UserOutlined />,
  //     danger: true,
  //     disabled: true,
  //   },
  // ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="page-wrapper"> 
      <Header />

      <div className="sidebar-page-container style-two" style={{paddingTop: 0}}>
        <div className="auto-container">
          <div className="row clearfix">
            {/* Content Side */}
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              <div className="course-detail">
                <div className="course-detail_inner">
                  <div className="course-detail_image">
                    <img
                      src={masjid?.thumbnail}
                      alt=""
                      style={{ height: "30rem" }}
                    />
                  </div>
                  <div className="course-detail_content">
                    <h3 className="course-detail_heading">{masjid?.name}</h3>
                    <div className="course-detail_info d-flex justify-content-between align-items-center flex-wrap">
                      {/* Author */}
                      <div className="course-detail_author">
                        <div className="course-detail_author-image">
                          <img src={masjid?.thumbnail} alt="" />
                        </div>
                        {masjid?.imam}
                        <span>Imam</span>
                      </div>
                      {/* List */}
                      <ul className="course-detail_list">
                        <li>
                          <span>-</span>-
                        </li>
                        <li>
                          <span>-</span>-
                        </li>
                        <li>
                          <span>-</span>-
                        </li>
                      </ul>
                      <div className="course-detail_price">
                        - <span>---</span>
                      </div>
                    </div>
                    <h4 className="course-detail_subtitle">Overview</h4>
                    <p style={{ wordBreak: "break-all" }}>
                      {masjid?.description}
                    </p>
                    {/* Product Info Tabs */}
                    <div className="course-detail-info-tabs">
                      {/* Product Tabs */}
                      <div className="course-detail-tabs tabs-box">
                        {/* Tab Btns */}
                        <ul className="tab-btns tab-buttons clearfix">
                          <li
                            data-tab="#prod-curriculum"
                            className="tab-btn active-btn"
                          >
                            Employees
                          </li>
                          <li data-tab="#prod-instructor" className="tab-btn">
                            Instructor
                          </li>
                          <li data-tab="#prod-review" className="tab-btn">
                            Review
                          </li>
                          <li data-tab="#prod-faq" className="tab-btn">
                            Faq
                          </li>
                        </ul>

                        {/* Tabs Container */}
                        {/* <div className="tabs-content">
                    <div className="tab active-tab" id="prod-curriculum">
                      <div className="content">
                        <h4 className="course-detail_subtitle">
                          Course Structure
                        </h4>
                        <p>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum. Unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium totam rem
                          aperiam, eaque ipsa quae ab illo inventore veritatis
                          et quasi architecto beatae
                        </p>
                        <ul className="accordion-box">
                          <li className="accordion block">
                            <div className="acc-btn active">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Literacy, level 1{" "}
                              <i className="preview">preview</i>
                            </div>
                            <div className="acc-content current">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Basic Quran Memorization, level 2
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Tajweed, level 1
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Intermediate Quran Level Course
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <h4 className="course-detail_subtitle">
                          Intermediate Quran Level Course
                        </h4>
                        <p>
                          Duis aute irure dolor in reprehenderit in voluptate
                          velit esse cillum. Unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium totam rem
                          aperiam, eaque ipsa quae ab illo inventore veritatis
                          et quasi architecto beatae
                        </p>
                        <ul className="accordion-box">
                          <li className="accordion block">
                            <div className="acc-btn active">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Memorization Lesson 1{" "}
                              <i className="preview">preview</i>
                            </div>
                            <div className="acc-content current">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Memorization Lesson 2
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Memorization Lesson 3
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Memorization Lesson 4
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon icon-plus flaticon-padlock" />{" "}
                                <span className="icon icon-minus flaticon-check-mark" />
                              </div>
                              Quran Memorization Lesson 5{" "}
                              <span className="questions">questions</span>{" "}
                              <i className="preview">preview</i>
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam rem aperiam, eaque
                                  ipsa quae ab illo inventore veritatis et quasi
                                  architecto beatae
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tab" id="prod-instructor">
                      <div className="content">
                        <div className="course-author_box">
                          <div className="course-author_box-inner">
                            <div className="course-author_box-image">
                              <img
                                src="assets/images/resource/author-14.png"
                                alt=""
                              />
                            </div>
                            <h5 className="course-author_box-heading">
                              Rajab Saad <span>Arabic Teacher</span>
                            </h5>
                            <div className="course-author_box-text">
                              Duis aute irure dolor in reprehenderit in
                              voluptate velit esse cillum. Unde omnis iste natus
                              error sit voluptatem accusantium doloremque
                              laudantium totam
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="prod-review">
                      <div className="content">
                        <div className="course-review_box">
                          <h5>2 Review</h5>
                          <div className="author_outer">
                            <div className="course-author">
                              <div className="course-author_inner">
                                <div className="course-author_image">
                                  <img
                                    src="assets/images/resource/author-15.png"
                                    alt=""
                                  />
                                </div>
                                <h5 className="course-author_heading">
                                  Ibrahim Kabir
                                </h5>
                                <div className="course-author_text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam. Alteration in
                                  some form, lipsum is simply free text by
                                  injected{" "}
                                </div>
                              </div>
                            </div>
                            <div className="course-author">
                              <div className="course-author_inner">
                                <div className="course-author_image">
                                  <img
                                    src="assets/images/resource/author-16.png"
                                    alt=""
                                  />
                                </div>
                                <h5 className="course-author_heading">
                                  Mahfuz Riad{" "}
                                </h5>
                                <div className="course-author_text">
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem accusantium
                                  doloremque laudantium totam. Alteration in
                                  some form, lipsum is simply free text by
                                  injected{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="course-comment_form">
                            <h5>Add a Review</h5>
                            <form
                              method="post"
                              action="https://themazine.com/html/alquran/contact.html"
                            >
                              <div className="row clearfix">
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                  <input
                                    type="text"
                                    name="username"
                                    placeholder="Your Name"
                                  />
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                  <input
                                    type="text"
                                    name="email"
                                    placeholder="Email Address"
                                  />
                                </div>
                                <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                  <textarea
                                    className=""
                                    name="message"
                                    placeholder="Type Your Message"
                                    defaultValue={""}
                                  />
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                  <div className="button-box">
                                    <button
                                      type="submit"
                                      className="theme-btn btn-style-one"
                                    >
                                      <span className="btn-wrap">
                                        <span className="text-one">
                                          Leave a Review
                                        </span>
                                        <span className="text-two">
                                          Leave a Review
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab" id="prod-faq">
                      <div className="content">
                        <ul className="accordion-box_two style-two">
                          <li className="accordion block">
                            <div className="acc-btn active">
                              <div className="icon-outer">
                                <span className="icon">
                                  <img
                                    src="assets/images/icons/faq-arrow.png"
                                    alt=""
                                  />
                                </span>
                              </div>
                              Can I choose the sessions timings on my own
                              convenience?
                            </div>
                            <div className="acc-content current">
                              <div className="content">
                                <div className="text">
                                  Yes, you can. Once you are enrolled in the
                                  course, the Support team will email you to
                                  arrange the timing of the sessions with you.
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon">
                                  <img
                                    src="assets/images/icons/faq-arrow.png"
                                    alt=""
                                  />
                                </span>
                              </div>
                              Why online Islamic classes have charges?
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Yes, you can. Once you are enrolled in the
                                  course, the Support team will email you to
                                  arrange the timing of the sessions with you.
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon">
                                  <img
                                    src="assets/images/icons/faq-arrow.png"
                                    alt=""
                                  />
                                </span>
                              </div>
                              Where are the Quran Tutors from &amp; are they
                              qualified?
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Yes, you can. Once you are enrolled in the
                                  course, the Support team will email you to
                                  arrange the timing of the sessions with you.
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="accordion block">
                            <div className="acc-btn">
                              <div className="icon-outer">
                                <span className="icon">
                                  <img
                                    src="assets/images/icons/faq-arrow.png"
                                    alt=""
                                  />
                                </span>
                              </div>
                              What if a teacher is absent and misses my class?
                            </div>
                            <div className="acc-content">
                              <div className="content">
                                <div className="text">
                                  Yes, you can. Once you are enrolled in the
                                  course, the Support team will email you to
                                  arrange the timing of the sessions with you.
                                  Duis aute irure dolor in reprehenderit in
                                  voluptate velit esse cillum. Unde omnis iste
                                  natus error sit voluptatem
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Sidebar Side */}
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                {/* Course Widget */}
                <div className="sidebar-widget course-widget">
                  <div className="widget-content">
                    {/* Sidebar Title */}
                    <div className="sidebar-title">
                      <h4>Info</h4>
                    </div>
                    <ul className="course-list">
                      <li>
                        Jamaath <span>""</span>
                      </li>
                      <li>
                        Imam <span>{masjid?.imam}</span>
                      </li>
                      <li>
                        Founded Year
                        <span>
                          {moment(masjid?.foundedYear).format("DD/MM/YYYY")}
                        </span>
                      </li>
                      <li>
                        Founder<span>{masjid?.founder}</span>
                      </li>
                      <li>
                        Last Reconstruction
                        <span>
                          {moment(masjid?.lastReconstruction).format(
                            "DD/MM/YYYY"
                          )}
                        </span>
                      </li>
                      <li>
                        Working Time<span>{masjid?.workingTime}</span>
                      </li>
                    </ul>
                    {/* <a className="theme-btn course-appy_btn" href="#">
                Apply Now
              </a> */}
                  </div>
                </div>
                {/* Helpline Widget */}
                <div className="sidebar-widget helpline-widget">
                  <div className="widget-content">
                    <div className="image">
                      <img
                        src="assets/images/icons/helpline-widget.png"
                        alt=""
                      />
                    </div>
                    <h4 className="helpline-title">
                      If you have any further query then you can contact our
                      helpline
                    </h4>
                    <div className="helpline-widget_phone flaticon-phone-call" />
                    <div className="helpline-widget_number">
                      Ph: {masjid?.phone}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
}

export default MasjidDetail;
