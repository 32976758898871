import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Dropdown } from "react-bootstrap";
import { Input, MenuProps, Select } from "antd";
// import states from "../../utils/states";
import { Button, message, Space, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "./Home.css";
import AutoScrollBanner from "../../components/auto-scroll-banner/AutoScrollBanner";
import axios from "axios";
import Footer from "../../components/layouts/footer/Footer";

import { getToken } from "firebase/messaging";

function Home() {
  const [states, setStates] = useState<any>([]);
  const [searchTypes, setSearchTypes] = useState<any>([]);
  const [deceasedPeople, setDeceasedPeople] = useState<any>([]);
  const [loading, setLoading] = useState<any>([]);
  const [error, setError] = useState<any>([]);

  const [currentDistricts, setCurrentDistricts] = useState<any>([]);
  const [selectedSearchType, setSelectedSearchType] = useState("Masjid");
  const [currentPanchayaths, setCurrentPanchayaths] = useState<any>([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedPanchayath, setSelectedPanchayath] = useState("");
  const [selectedJamaath, setSelectedJamaath] = useState("");

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});

  const [isTokenFound, setTokenFound] = useState(false);

  const fetchSearchTypes = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/places/searchTypes`;
      const response = await axios.get(apiUrl);
      setSearchTypes(response?.data?.data?.searchTypes);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchPlaces = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/places`;
      const response = await axios.get(apiUrl);
      setStates(response?.data?.data?.places);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchDeceasedPeople = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/deceased-people/home`;
      const response = await axios.get(apiUrl);
      setDeceasedPeople(response?.data?.data?.deceased_people);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSearchTypes();
    fetchPlaces();
    fetchDeceasedPeople();
  }, []);

  const onChange = (state: string) => {
    let filteredState = states?.states?.find(
      (e: any, i: any) => e.state === state
    );
    console.log(
      "🚀 ~ file: home.tsx:17 ~ onChange ~ filteredState:",
      filteredState
    );

    let districts = filteredState?.districts?.map((e: any, i: any) => ({
      label: e,
      value: e,
    }));

    setCurrentDistricts(districts);
    setSelectedState(state);
  };

  const onDistrictChange = (district: string) => {
    let filteredDistrict = states?.districts?.find(
      (e: any, i: any) => e.name === district
    );
    console.log(
      "🚀 ~ file: home.tsx:17 ~ onChange ~ filteredDistrict:",
      filteredDistrict
    );

    let districts = filteredDistrict?.panchayats?.map((e: any, i: any) => ({
      label: e,
      value: e,
    }));

    setCurrentPanchayaths(districts);
    setSelectedDistrict(filteredDistrict?.name as string);
  };

  const onPanchayatChange = (panchayat: string) => {
    setSelectedPanchayath(panchayat);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const items: MenuProps["items"] = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4rd menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const handleMenuClick = (e: any) => {
    console.log("click", e);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleSearchTypeChange = (e: any) => {
    setSelectedSearchType(e);
  };

  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.remove("mobile-menu-visible");
  }, []);

  return (
    <div className="page-wrapper">
      {/* Cursor */}
      {/* <div className="cursor" />
      <div className="cursor-follower" /> */}
      {/* Cursor End */}
      {/* Preloader */}
      {/* <div className="preloader" /> */}
      {/* End Preloader */}
      {/* Main Header */}
      <Header />
      {/* End Main Header */}

      <AutoScrollBanner notice="Indian Masjid awaits with great anticipation for the honor of being inaugurated by the esteemed Chief Minister of Kerala, Shri. Pinarayi Vijayan and Sports Minister of Kerala, Shri. V Abdurahiman." />

      {/* Slider One */}
      <section className="slider-one">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper: any) => console.log(swiper)}
        >
          <SwiperSlide>
            <div>
              <div
                className="slider-one_image-layer"
                style={{
                  backgroundImage: "url(assets/images/main-slider/1.jpg)",
                }}
              />
              <div className="auto-container">
                {/* Content Column */}
                <div className="slider-one_content">
                  <div className="slider-one_content-inner">
                    <div className="slider-one_title">
                      {t("home_banner_text_1")}
                    </div>
                    <h1 className="slider-one_heading">
                      {/* Another World Is <br /> Waiting For You */}
                      {t("home_banner_title")}
                    </h1>
                    <div className="slider-one_text">
                      {t("greeting")}
                      {/* Another World Is Waiting For You */}
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={selectedSearchType}
                        variant="success"
                        className="seachtype-dropdown"
                      >
                        {searchTypes?.map((x: any, i: number) => (
                          <Dropdown.Item
                            onClick={() => handleSearchTypeChange(x.value)}
                          >
                            {x.label}
                          </Dropdown.Item>
                        ))}

                        {/* <Dropdown.Item
                          onClick={() => handleSearchTypeChange("Masjid")}
                        >
                          Masjid
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleSearchTypeChange("Scholars")}
                        >
                          Scholars
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleSearchTypeChange("Madrasa")}
                        >
                          Madrasa
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleSearchTypeChange("Educational Institute")
                          }
                        >
                          Educational Institute
                        </Dropdown.Item> */}
                      </DropdownButton>

                      {/* <Select
                        size={"large"}
                        style={{
                          width: 200,
                          height: 55,
                          marginRight: "1rem",
                          color: "black",
                        }}
                        placeholder="Mosque"
                        // defaultValue={'mosque'}
                        // onChange={onChange}
                        options={[{
                          value: 'mosque',
                          label: 'Mosque',
                        },{
                          value: 'educationalInstitute',
                          label: 'Educational Institute',
                        },
                        {
                          value: 'madrasa',
                          label: 'Madrasa',
                        },
                      ]}
                      /> */}

                      {selectedSearchType !== "scholars" && (
                        <>
                          <Select
                            size={"large"}
                            style={{
                              width: 200,
                              height: 55,
                              marginRight: "1rem",
                              color: "black",
                            }}
                            showSearch
                            placeholder="Select a state"
                            optionFilterProp="children"
                            onChange={onChange}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={states?.states?.map((e: any, i: any) => ({
                              value: e.state,
                              label: e.state,
                            }))}
                          />

                          <Select
                            size={"large"}
                            style={{
                              width: 200,
                              height: 55,
                              marginRight: "1rem",
                            }}
                            showSearch
                            placeholder="Select a district"
                            optionFilterProp="children"
                            onChange={onDistrictChange}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={currentDistricts}
                          />

                          <Select
                            size={"large"}
                            style={{ width: 200, height: 55 }}
                            showSearch
                            placeholder="Select locality"
                            optionFilterProp="children"
                            onChange={onPanchayatChange}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={currentPanchayaths}
                          />
                        </>
                      )}

                      {selectedSearchType === "scholars" && (
                        <Input
                          style={{ width: 200, height: 55 }}
                          placeholder="Search by name"
                        />
                      )}

                      <div className="slider-one_button">
                        <Link
                          to={`/mosques?type=${selectedSearchType}&state=${selectedState.toLocaleLowerCase()}&dis=${selectedDistrict.toLocaleLowerCase()}&loc=${selectedPanchayath.toLocaleLowerCase()}&jam=${selectedJamaath.toLocaleLowerCase()}`}
                          className="theme-btn btn-style-two"
                        >
                          <span className="btn-wrap">
                            <span className="text-one">Search</span>
                            <span className="text-two">Search</span>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div
                className="slider-one_image-layer"
                style={{
                  backgroundImage: "url(assets/images/main-slider/2.jpg)",
                }}
              />
              <div className="auto-container">
                {/* Content Column */}
                <div className="slider-one_content">
                  <div className="slider-one_content-inner">
                    <div className="slider-one_title">
                      Largest Online Madrasah
                    </div>
                    <h1 className="slider-one_heading">
                      Islamic Online <br /> Madrasah
                    </h1>
                    <div className="slider-one_text">
                      Islamic online madrasah is an ISO certified educational
                      institution of international standards
                    </div>
                    <div className="slider-one_button">
                      <a href="#" className="theme-btn btn-style-two">
                        <span className="btn-wrap">
                          <span className="text-one">Find the course</span>
                          <span className="text-two">Find the course</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div
                className="slider-one_image-layer"
                style={{
                  backgroundImage: "url(assets/images/main-slider/3.jpg)",
                }}
              />
              <div className="auto-container">
                {/* Content Column */}
                <div className="slider-one_content">
                  <div className="slider-one_content-inner">
                    <div className="slider-one_title">
                      Largest Online Madrasah
                    </div>
                    <h1 className="slider-one_heading">
                      Islamic Online <br /> Madrasah
                    </h1>
                    <div className="slider-one_text">
                      Islamic online madrasah is an ISO certified educational
                      institution of international standards
                    </div>
                    <div className="slider-one_button">
                      <a href="#" className="theme-btn btn-style-two">
                        <span className="btn-wrap">
                          <span className="text-one">Find the course</span>
                          <span className="text-two">Find the course</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      {/* End Main Slider Section */}
      {/* Featured One */}
      <section className="featured-one">
        <div className="auto-container">
          <div
            className="inner-container"
            style={{ backgroundImage: "url(assets/images/icons/featured.png)" }}
          >
            <div className="row clearfix">
              {/* Feature Block One */}
              <div className="feature-block_one col-lg-4 col-md-6 col-sm-12">
                <div
                  className="feature-block_one-inner"
                  style={{ paddingLeft: 0 }}
                >
                  <div
                    className="feature-block_one-icon flaticon-islamic"
                    style={{ display: "none" }}
                  />
                  {/* <img src="assets/images/icons/mosque.png"></img> */}
                  {/* Explore All <br /> Masjids */}
                  <img
                    className="home-flip-img"
                    src="assets/images/icons/mosque.png"
                    style={{
                      border: "2px solid #333333",
                      height: "7rem",
                      borderRadius: "50%",
                      padding: "17px",
                      backgroundColor: "#f3b815",
                      marginRight: "1.5rem",
                    }}
                  ></img>
                  {t("home_box_banner_1_1")}
                </div>
              </div>
              {/* Feature Block One */}
              <div className="feature-block_one col-lg-4 col-md-6 col-sm-12">
                <div
                  className="feature-block_one-inner"
                  style={{ paddingLeft: 0 }}
                >
                  <div
                    className="feature-block_one-icon flaticon-islamic"
                    style={{ display: "none" }}
                  />
                  <img
                    className="home-flip-img"
                    src="assets/images/icons/muslim.png"
                    style={{
                      border: "2px solid #333333",
                      height: "7rem",
                      borderRadius: "50%",
                      padding: "17px",
                      backgroundColor: "#f3b815",
                      marginRight: "1.5rem",
                    }}
                  ></img>
                  {t("home_box_banner_1_2")}

                  {/* Connect With
                  <br /> Scholars */}
                </div>
              </div>
              {/* Feature Block One */}
              <div className="feature-block_one col-lg-4 col-md-6 col-sm-12">
                <div className="feature-block_one-inner">
                  <div className="feature-block_one-icon flaticon-quran" />
                  {t("home_box_banner_1_3")}

                  {/* Search Madrasas <br /> And Institutes */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Featured One */}
      {/* Welcome One */}
      <section className="welcome-one">
        <div
          className="welcome-one_pattern"
          style={{
            backgroundImage: "url(assets/images/background/pattern-1.png)",
          }}
        />
        <div
          className="welcome-one_pattern-two"
          style={{
            backgroundImage: "url(assets/images/background/pattern-2.png)",
          }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Content Column */}
            <div className="welcome-one_content-column col-lg-6 col-md-12 col-sm-12">
              <div className="welcome-one_content-outer">
                {/* Sec Title */}
                <div className="sec-title">
                  <div className="sec-title_title d-flex align-items-center">
                    {t("home_intro_1")}{" "}
                    <span>
                      <img src="assets/images/icons/bismillah-2.png" alt="" />
                    </span>
                  </div>
                  <h2 className="sec-title_heading">{t("home_intro_2")}</h2>
                  <div className="sec-title_text">{t("home_intro_3")}</div>
                </div>
                <div className="welcome-one_content">
                  <div className="welcome-one_content-image">
                    <img src="assets/images/resource/welcome.jpg" alt="" />
                  </div>
                  <p>{t("home_intro_4")}</p>
                  <div className="welcome-one_learn">
                    <span className="welcome-one_learn-icon flaticon-mosque" />
                    {t("home_intro_5_1")}
                    <br /> {t("home_intro_5_2")}
                    {/* Learn Online at Your <br /> Own Pace */}
                  </div>
                </div>
                <p>{t("home_intro_6")}</p>
              </div>
            </div>
            {/* Image Column */}
            <div className="welcome-one_image-column col-lg-6 col-md-12 col-sm-12">
              <div className="welcome-one_image-outer">
                <div className="welcome-one_ameen">
                  <img src="assets/images/icons/ameen-1.png" alt="" />
                </div>
                <div className="welcome-one_image">
                  <img src="assets/images/resource/welcome-1.jpg" alt="" />
                </div>
                <div className="welcome-one_years d-flex align-items-center flex-wrap">
                  <span className="fa-solid fa-globe fa-fw" />
                  {/* Since 1995 Operating in the world */}
                  {t("home_intro_7")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Welcome One */}
      {/* Service One */}
      {/* End Service One */}
      {/* Students One */}
      {/* End Students One */}
      {/* Courses One */}
      {/* End Courses One */}
      {/* CTA One */}

      <section className="cta-one" style={{ marginBottom: "2rem" }}>
        <div className="auto-container">
          <div className="inner-container d-flex justify-content-between align-items-center flex-wrap">
            <div
              className="cta-one_bg"
              style={{
                backgroundImage: "url(assets/images/background/cta-one_bg.png)",
              }}
            />
            <h3 className="cta-one_heading">
              {t("home_quote_1_1")} <br /> {t("home_quote_1_2")}
            </h3>
          </div>
        </div>
      </section>

      {/* End CTA One */}
      {/* Institute One */}

      {/* End Institute One */}
      {/* Testimonial One */}

      {/* End Testimonial One */}
      {/* Price One */}

      {/* End Price One */}
      {/* Gallery One */}

      {/* End Gallery One */}
      {/* News One */}
      {/* <section className="news-one">
        <div className="auto-container">
          <div className="sec-title centered">
            <div className="sec-title_title">{t("home_blogs_title_1")}</div>
            <h2 className="sec-title_heading">
            {t("home_blogs_title_2_1")} &amp; {t("home_blogs_title_2_2")} <br /> {t("home_blogs_title_2_3")}
            </h2>
          </div>
          <div className="row clearfix">
            <div className="news-block_one col-lg-4 col-md-6 col-sm-12">
              <div
                className="news-block_one-inner wow fadeInLeft"
                data-wow-delay="150ms"
                data-wow-duration="1500ms"
              >
                <div className="news-block_one-image">
                  <a href="#">
                    <img src="assets/images/resource/news-1.jpg" alt="" />
                  </a>
                </div>
                <div className="news-block_one-content">
                  <ul className="news-block_one-meta">
                    <li>
                      <span className="icon fa-brands fa-rocketchat fa-fw" />
                      03 Comment
                    </li>
                    <li>
                      <span className="icon fa-solid fa-clock fa-fw" />
                      October 18 2023
                    </li>
                  </ul>
                  <h5 className="news-block_one-heading">
                    <a href="#">
                      Abandoning Prayer – Why to Avoid at all Costs?
                    </a>
                  </h5>
                  <div className="news-block_one-text">
                    There are many variations of passages of lorem Ipsum
                    available islam courses
                  </div>
                  <div className="news-block_one-info d-flex justify-content-between align-items-center flex-wrap">
                    <div className="news-block_one-author">
                      <div className="news-block_one-author_image">
                        <img src="assets/images/resource/author-2.png" alt="" />
                      </div>
                      amir khan
                    </div>
                    <a className="news-block_one-more theme-btn">read more</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block_one col-lg-4 col-md-6 col-sm-12">
              <div
                className="news-block_one-inner wow fadeInUp"
                data-wow-delay="150ms"
                data-wow-duration="1500ms"
              >
                <div className="news-block_one-image">
                  <a href="#">
                    <img src="assets/images/resource/news-2.jpg" alt="" />
                  </a>
                </div>
                <div className="news-block_one-content">
                  <ul className="news-block_one-meta">
                    <li>
                      <span className="icon fa-brands fa-rocketchat fa-fw" />
                      03 Comment
                    </li>
                    <li>
                      <span className="icon fa-solid fa-clock fa-fw" />
                      October 18 2023
                    </li>
                  </ul>
                  <h5 className="news-block_one-heading">
                    <a href="#">
                      What are the Conditions of the Shahadah in Islam
                    </a>
                  </h5>
                  <div className="news-block_one-text">
                    There are many variations of passages of lorem Ipsum
                    available islam courses
                  </div>
                  <div className="news-block_one-info d-flex justify-content-between align-items-center flex-wrap">
                    <div className="news-block_one-author">
                      <div className="news-block_one-author_image">
                        <img src="assets/images/resource/author-3.png" alt="" />
                      </div>
                      amir khan
                    </div>
                    <a className="news-block_one-more theme-btn">read more</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="news-block_one col-lg-4 col-md-6 col-sm-12">
              <div
                className="news-block_one-inner wow fadeInRight"
                data-wow-delay="150ms"
                data-wow-duration="1500ms"
              >
                <div className="news-block_one-image">
                  <a href="#">
                    <img src="assets/images/resource/news-3.jpg" alt="" />
                  </a>
                </div>
                <div className="news-block_one-content">
                  <ul className="news-block_one-meta">
                    <li>
                      <span className="icon fa-brands fa-rocketchat fa-fw" />
                      03 Comment
                    </li>
                    <li>
                      <span className="icon fa-solid fa-clock fa-fw" />
                      October 18 2023
                    </li>
                  </ul>
                  <h5 className="news-block_one-heading">
                    <a href="#">
                      Types of Khawf (Fear) and Tawakkul (Reliance) in Islam
                    </a>
                  </h5>
                  <div className="news-block_one-text">
                    There are many variations of passages of lorem Ipsum
                    available islam courses
                  </div>
                  <div className="news-block_one-info d-flex justify-content-between align-items-center flex-wrap">
                    <div className="news-block_one-author">
                      <div className="news-block_one-author_image">
                        <img src="assets/images/resource/author-4.png" alt="" />
                      </div>
                      amir khan
                    </div>
                    <a className="news-block_one-more theme-btn">read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* End News One */}
      {/* CTA One */}

      {/* Deceased People Starts */}
      {deceasedPeople?.length > 0 && (
        <div className="dp-section auto-container">
          <div className="title">
            <h3 style={{ color: "black" }}>DECEASED...</h3>
          </div>

          <div className="dp-container">
            {deceasedPeople?.map((x: any, i: any) => (
              <div className="dp-card">
                <img src={x.photo} alt="Card Image 1" />
                <h4 style={{color: 'black', marginTop: '10px'}}>{x.name}</h4>
                <p>{x.address}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Deceased People Ends */}

      <section className="cta-two">
        <div className="auto-container">
          <div className="inner-container d-flex justify-content-between align-items-center flex-wrap">
            <div
              className="cta-two_bg"
              style={{
                backgroundImage: "url(assets/images/background/cta-one_bg.png)",
              }}
            />
            <div className="cta-two_icon flaticon-nabawi-mosque" />
            <h3 className="cta-two_heading">
              {t("home_quote_2_1")} <br /> {t("home_quote_2_2")}
            </h3>
          </div>
        </div>
      </section>
      {/* End CTA One */}
      {/* Footer Style */}
      <Footer />
      {/* End Footer Style */}
      {/* Search Popup */}
      <div className="search-popup">
        <div className="color-layer" />
        <button className="close-search">
          <span className="flaticon-close-1" />
        </button>
        <form method="post" action="">
          <div className="form-group">
            <input
              type="search"
              name="search-field"
              placeholder="Search Here"
              required
            />
            <button
              className="fa fa-solid fa-magnifying-glass fa-fw"
              type="button"
            />
          </div>
        </form>
      </div>
      {/* End Search Popup */}
    </div>
  );
}

export default Home;
