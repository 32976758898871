import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Button, Select } from "antd";
// import states from "../../utils/states";
import { useLocation } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import axios from "axios";

import "swiper/css";
import "./MosqueList.css";
import { Link } from "react-router-dom";
import ScholarCard from "../../components/scholars-list/scholar-card";
import Footer from "../../components/layouts/footer/Footer";

function MosquesList() {
  // Use the useLocation hook from react-router-dom
  const location = useLocation();

  // Get the query string from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter
  const [type, setType] = useState(queryParams.get("type"));
  const [state, setState] = useState(queryParams.get("state"));
  const [district, setDistrict] = useState(queryParams.get("dis"));
  const [panchayat, setPanchayat] = useState(queryParams.get("pan"));
  const [jamaath, setJamaath] = useState(queryParams.get("jamaath"));
  const [key, setKey] = useState(queryParams.get("key"));
  const [locality, setLocality] = useState(queryParams.get("loc"));

  // const state = queryParams.get("state");
  // const district = queryParams.get("dis");
  // const panchayat = queryParams.get("pan");
  // const jamaath = queryParams.get("jam");
  // const key = queryParams.get("key");
  // const locality = queryParams.get("loc");

  const [searchTypes, setSearchTypes] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedSearchType, setSelectedSearchType] = useState();
  const [selectedSearchTypeLabel, setSelectedSearchTypeLabel] =
    useState<any>();

  const [currentPanchayaths, setCurrentPanchayaths] = useState<any>([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedPanchayath, setSelectedPanchayath] = useState("");
  const [selectedJamaath, setSelectedJamaath] = useState("");
  const [currentDistricts, setCurrentDistricts] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ district:", district);
    fetchSearchTypes();
    fetchPlaces();
    setSelectedState(state as string);
    setSelectedDistrict(district as string);
    setSelectedPanchayath(locality as string);
  }, []);

  useEffect(() => {
    if (state && district) {
      onChange(state);
      onDistrictChange(district);
    }
  }, [state, district]);

  const fetchSearchTypes = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/places/searchTypes`;
      const response = await axios.get(apiUrl);
      setSearchTypes(response?.data?.data?.searchTypes);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchPlaces = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/places`;
      const response = await axios.get(apiUrl);
      setStates(response?.data?.data?.places);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchMosquesData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/mosques?state=${
        selectedState || ""
      }&dis=${selectedDistrict || ""}&loc=${selectedPanchayath || ""}&jam=${
        jamaath || ""
      }`;
      const response = await axios.get(apiUrl);
      setData(response?.data?.data?.mosques);
      setLoading(false);
    } catch (error: any) {
      if (panchayat === "panakkad" || locality === "panakkad") {
        setData(states.mosques.panakkad);
      }

      setError(error);
      setLoading(false);
    }
  };

  const fetchScholarsData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/scholars?state=${
        selectedState || ""
      }&dis=${selectedDistrict || ""}&loc=${selectedPanchayath || ""}`;

      const response = await axios.get(apiUrl);
      setData(response?.data?.data?.scholars);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  const fetchJamaathsData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/jamaaths?state=${
        selectedState || ""
      }&dis=${selectedDistrict || ""}&loc=${selectedPanchayath || ""}`;

      const response = await axios.get(apiUrl);
      setData(response?.data?.data?.jamaaths);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedSearchType(type as any);
    setSelectedSearchTypeLabel(type === 'mosques' ? 'Masjid' : type === 'jamaaths' ? 'Jamaath' : type === 'scholars' ? 'Scholar' : '')
    setSelectedDistrict(district as string);
  }, [type, state, district, panchayat]);

  useEffect(() => {
    console.log("hhhhhhhh", selectedDistrict);
    // Call APIs according to conditions
    if (selectedSearchType === "mosques") {
      fetchMosquesData();
    } else if (selectedSearchType === "scholars") {
      fetchScholarsData();
    } else if (selectedSearchType === "jamaaths") {
      fetchJamaathsData();
    } else {
      fetchMosquesData();
    }

    // Clean up function (optional)
    return () => {
      // Perform any cleanup or abort the ongoing requests
    };
  }, [
    type,
    state,
    district,
    panchayat,
    jamaath,
    key,
    selectedSearchType,
    selectedState,
    selectedDistrict,
    selectedPanchayath,
  ]); // The empty dependency array ensures that the effect runs once when the component mounts

  // const onChange = (state: string) => {
  //   let filteredState = states?.states?.find((e, i) => e.state === state);
  //   console.log(
  //     "🚀 ~ file: home.tsx:17 ~ onChange ~ filteredState:",
  //     filteredState
  //   );

  //   let districts = filteredState?.districts?.map((e, i) => ({
  //     label: e,
  //     value: e,
  //   }));

  //   setCurrentDistricts(districts);
  // };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChange = (state: string) => {
    let filteredState = states?.states?.find(
      (e: any, i: any) => e.state.toLowerCase() === state.toLowerCase()
    );

    let districts = filteredState?.districts?.map((e: any, i: any) => ({
      label: e,
      value: e,
    }));

    setCurrentDistricts(districts);
    setSelectedState(state);
  };

  const onDistrictChange = (district: string) => {
    console.log("🚀 ~ onDistrictChange ~ district:", district);
    let filteredDistrict = states?.districts?.find(
      (e: any, i: any) => e.name.toLowerCase() === district.toLowerCase()
    );
    console.log("🚀 ~ onDistrictChange ~ filteredDistrict:", filteredDistrict);

    let districts = filteredDistrict?.panchayats?.map((e: any, i: any) => ({
      label: e,
      value: e,
    }));

    setCurrentPanchayaths(districts);
    setSelectedDistrict(filteredDistrict?.name as string);
  };

  const onPanchayatChange = (panchayat: string) => {
    setSelectedPanchayath(panchayat);
  };

  const handleSearchTypeChange = (e: any) => {
    setSelectedSearchType(e?.value);
    setSelectedSearchTypeLabel(e?.label);
  };

  return (
    <div className="page-wrapper">
      {/* Cursor */}
      {/* <div className="cursor" />
      <div className="cursor-follower" /> */}
      {/* Cursor End */}
      {/* Preloader */}
      {/* <div className="preloader" /> */}
      {/* End Preloader */}
      {/* Main Header */}
      <Header />
      {/* End Main Header */}

      <section
        className="courses-two"
        style={{
          backgroundImage: "url(assets/images/background/courses-two_bg.png)",
          paddingTop: 0,
        }}
      >
        <div className="filterBox">
          <DropdownButton
            id="dropdown-basic-button"
            title={selectedSearchTypeLabel}
            variant="success"
            className="searchtype-dropdown"
          >
            {searchTypes?.map((x: any, i: number) => (
              <Dropdown.Item onClick={() => handleSearchTypeChange(x)}>
                {x.label}
              </Dropdown.Item>
            ))}

            {/* <Dropdown.Item onClick={() => handleSearchTypeChange("Masjid")}>
              Masjid
            </Dropdown.Item> */}

            {/* <Dropdown.Item onClick={() => handleSearchTypeChange("Scholars")}>
              Scholars
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSearchTypeChange("Jamaaths")}>
              Jamaaths
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleSearchTypeChange("Madrasa")}>
              Madrasa
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSearchTypeChange("Educational Institute")}
            >
              Educational Institute
            </Dropdown.Item> */}
          </DropdownButton>

          <Select
            size={"large"}
            style={{
              width: 200,
              height: 55,
              marginRight: "1rem",
              color: "black",
            }}
            showSearch
            placeholder="Select a state"
            optionFilterProp="children"
            onChange={onChange}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={states?.states?.map((e: any, i: any) => ({
              value: e.state,
              label: e.state,
            }))}
            value={selectedState}
          />

          <Select
            size={"large"}
            style={{
              width: 200,
              height: 55,
              marginRight: "1rem",
            }}
            showSearch
            placeholder="Select a district"
            optionFilterProp="children"
            onChange={onDistrictChange}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={currentDistricts}
            value={selectedDistrict}
          />

          <Select
            size={"large"}
            style={{ width: 200, height: 55 }}
            showSearch
            placeholder="Select locality"
            optionFilterProp="children"
            onChange={onPanchayatChange}
            // onSearch={onSearch}
            filterOption={filterOption}
            options={currentPanchayaths}
            value={selectedPanchayath}
          />
        </div>

        <div className="auto-container">
          <div className="row clearfix" style={{ marginBottom: "6rem" }}>
            {/* Course Block Two */}
            {data?.map((e: any, i: number) =>
              selectedSearchType === "mosques" ? (
                <div className="course-block_two col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="course-block_two-inner wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="course-block_two-image">
                      <a>
                        <Swiper
                          // navigation
                          // spaceBetween={50}
                          // slidesPerView={1}
                          // onSlideChange={() => console.log("slide change")}
                          // onSwiper={(swiper: any) => console.log(swiper)}

                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          spaceBetween={50}
                          slidesPerView={1}
                          navigation
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          onSwiper={(swiper: any) => console.log(swiper)}
                          onSlideChange={() => console.log("slide change")}
                        >
                          {e?.photos?.length > 0 &&
                            e.photos?.map((photo: string) => (
                              <SwiperSlide>
                                <img
                                  src={photo}
                                  alt=""
                                  style={{ height: "12.5rem" }}
                                  onError={(e: any) => {
                                    e.target.src =
                                      "assets/images/resource/course-5.jpg"; // Fallback image source
                                    e.target.alt = "Image Error"; // Alt text for the fallback image
                                  }}
                                />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </a>
                    </div>
                    <div className="course-block_two-content">
                      <div className="course-block_two-icon">
                        <img
                          src="assets/images/icons/course-block_two.png"
                          alt=""
                        />
                      </div>
                      <Link
                        className="course-block_two-study"
                        style={{ zIndex: 10 }}
                        to={"/masjid/" + e._id}
                      >
                        Explore
                      </Link>
                      <h4 className="course-block_two-heading">
                        <a href="#">{e?.name}</a>
                      </h4>
                      <ul className="course-block_two-list d-flex justify-content-between flex-wrap align-items-center">
                        <li>{e?.jamaath?.name}</li>
                        {/* <li>
                        <span>10</span>weeks
                      </li>
                      <li>
                        <span>50</span>enroll
                      </li> */}
                      </ul>
                      {/* <div className="course-block_two-lower d-flex justify-content-between flex-wrap">
                      <div className="course-block_two-author">
                        <div className="course-block_two-author_image">
                          <img
                            src="assets/images/resource/author-5.png"
                            alt=""
                          />
                        </div>
                        <strong>Habib Al Noor</strong>
                        Arabic Teacher
                      </div>
                      <div className="course-block_two-price">
                        $22.00 <span>course free</span>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              ) : selectedSearchType === "scholars" ? (
                <ScholarCard data={e} />
              ) : selectedSearchType === "jamaaths" ? (
                <div className="course-block_two col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="course-block_two-inner wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1000ms"
                  >
                    <div className="course-block_two-image">
                      <a>
                        <Swiper
                          // navigation
                          // spaceBetween={50}
                          // slidesPerView={1}
                          // onSlideChange={() => console.log("slide change")}
                          // onSwiper={(swiper: any) => console.log(swiper)}

                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          spaceBetween={50}
                          slidesPerView={1}
                          navigation
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          onSwiper={(swiper: any) => console.log(swiper)}
                          onSlideChange={() => console.log("slide change")}
                        >
                          {e?.images?.length > 0 &&
                            e.images?.map((photo: string) => (
                              <SwiperSlide>
                                <img
                                  src={photo}
                                  alt=""
                                  style={{ height: "12.5rem" }}
                                  onError={(e: any) => {
                                    e.target.src =
                                      "assets/images/resource/course-5.jpg"; // Fallback image source
                                    e.target.alt = "Image Error"; // Alt text for the fallback image
                                  }}
                                />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                      </a>
                    </div>
                    <div className="course-block_two-content">
                      <div className="course-block_two-icon">
                        <img
                          src="assets/images/icons/course-block_two.png"
                          alt=""
                        />
                      </div>
                      <Link
                        className="course-block_two-study"
                        style={{ zIndex: 10 }}
                        to={"/jamaath/" + e._id}
                      >
                        Explore
                      </Link>
                      <h4 className="course-block_two-heading">
                        <a href="#">{e?.name}</a>
                      </h4>
                      <ul className="course-block_two-list d-flex justify-content-between flex-wrap align-items-center">
                        <li>{e?.jamaath?.name}</li>
                        {/* <li>
                        <span>10</span>weeks
                      </li>
                      <li>
                        <span>50</span>enroll
                      </li> */}
                      </ul>
                      {/* <div className="course-block_two-lower d-flex justify-content-between flex-wrap">
                      <div className="course-block_two-author">
                        <div className="course-block_two-author_image">
                          <img
                            src="assets/images/resource/author-5.png"
                            alt=""
                          />
                        </div>
                        <strong>Habib Al Noor</strong>
                        Arabic Teacher
                      </div>
                      <div className="course-block_two-price">
                        $22.00 <span>course free</span>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
      </section>

      {/* Footer Style */}
      <Footer />
      {/* End Footer Style */}
      {/* Search Popup */}
      <div className="search-popup">
        <div className="color-layer" />
        <button className="close-search">
          <span className="flaticon-close-1" />
        </button>
        <form
          method="post"
          action="https://themazine.com/html/alquran/blog.html"
        >
          <div className="form-group">
            <input
              type="search"
              name="search-field"
              placeholder="Search Here"
              required
            />
            <button
              className="fa fa-solid fa-magnifying-glass fa-fw"
              type="submit"
            />
          </div>
        </form>
      </div>
      {/* End Search Popup */}
    </div>
  );
}

export default MosquesList;
