import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Dropdown } from "react-bootstrap";
import { Select } from "antd";
import states from "../../utils/states";
import { useLocation } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import axios from "axios";

import "swiper/css";
import { Link } from "react-router-dom";

function Inaugurations() {
  // Use the useLocation hook from react-router-dom
  const location = useLocation();

  // Get the query string from the location object
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter
  const type = queryParams.get("type");
  console.log("🚀 ~ file: MosquesList.tsx:21 ~ MosquesList ~ type:", type);
  const state = queryParams.get("state");
  console.log("🚀 ~ file: MosquesList.tsx:23 ~ MosquesList ~ state:", state);
  const district = queryParams.get("dis");
  console.log(
    "🚀 ~ file: MosquesList.tsx:25 ~ MosquesList ~ district:",
    district
  );
  const panchayat = queryParams.get("pan");
  console.log(
    "🚀 ~ file: MosquesList.tsx:27 ~ MosquesList ~ panchayat:",
    panchayat
  );
  const jamaath = queryParams.get("jam");
  console.log("🚀 ~ file: MosquesList.tsx:29 ~ MosquesList ~ jam:", jamaath);
  const key = queryParams.get("key");
  console.log("🚀 ~ file: MosquesList.tsx:29 ~ MosquesList ~ key:", key);

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Define your API endpoint
    const apiUrl = `${process.env.REACT_APP_API_BASEURL}/mosques?state=${state}&dis=${district}&pan=${panchayat}&jam=${jamaath}`;

    // Inside useEffect, create a function to make the API call
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log(
          "🚀 ~ file: MosquesList.tsx:47 ~ fetchData ~ response:",
          response
        );
        setData(response?.data?.data?.mosques);
        setLoading(false);
      } catch (error: any) {
        if (panchayat === "panakkad") {
          setData(states.mosques.panakkad);
        }

        setError(error);
        setLoading(false);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();

    // Clean up function (optional)
    return () => {
      // Perform any cleanup or abort the ongoing requests
    };
  }, [type, state, district, panchayat, jamaath, key]); // The empty dependency array ensures that the effect runs once when the component mounts

  const [currentDistricts, setCurrentDistricts] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");

  const onChange = (state: string) => {
    let filteredState = states?.states?.find((e, i) => e.state === state);
    console.log(
      "🚀 ~ file: home.tsx:17 ~ onChange ~ filteredState:",
      filteredState
    );

    let districts = filteredState?.districts?.map((e, i) => ({
      label: e,
      value: e,
    }));

    setCurrentDistricts(districts);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    document.body.classList.remove("mobile-menu-visible");
  }, [])
  

  return (
    <div className="page-wrapper">
      {/* Cursor */}
      {/* <div className="cursor" />
      <div className="cursor-follower" /> */}
      {/* Cursor End */}
      {/* Preloader */}
      {/* <div className="preloader" /> */}
      {/* End Preloader */}
      {/* Main Header */}
      <Header />
      {/* End Main Header */}

      <section className="events-one">
        <div className="auto-container">
          <div className="row clearfix">
            {/* Event Block One */}
            <div className="event-block_one col-lg-4 col-md-6 col-sm-12">
              <div className="event-block_one-inner">
                <div className="event-block_one-image">
                  <a href="event-detail.html"><img style={{height: '15rem'}} src="assets/images/resource/panakkad-thangal.jpeg" alt="" /></a>
                </div>
                <div className="event-block_one-content">
                  <div className="event-block_one-time">25 Nov 2023</div>
                  {/* <div className="event-block_one-location"><i className="fa-solid fa-location-dot fa-fw" /> Fiolveien 230, Norway</div> */}
                  <h5 className="event-block_one-heading"><Link to="/inaugurations">Inauguration Of Arabic Version By Panakkad Sayyid Sadiq Ali Shihab Thangal</Link></h5>
                  {/* <div className="event-block_one-price d-flex justify-content-between align-items-center flex-wrap">
                    Upcoming Event Price <span>$12.00</span>
                  </div> */}
                </div>
              </div>
            </div>
            {/* Event Block One */}
            {/* <div className="event-block_one col-lg-4 col-md-6 col-sm-12">
              <div className="event-block_one-inner">
                <div className="event-block_one-image">
                  <a href="event-detail.html"><img style={{height: '15rem'}} src="assets/images/resource/palayam-imam.jpeg" alt="" /></a>
                </div>
                <div className="event-block_one-content">
                <div className="event-block_one-time">29 Nov 2023</div>
                  <h5 className="event-block_one-heading"><Link to="/inaugurations">Inauguration of online mahal services by V P Suhaib Moulavi (Imam of Palayam Juma Masjid)</Link></h5>
                </div>
              </div>
            </div> */}
            {/* Event Block One */}
            <div className="event-block_one col-lg-4 col-md-6 col-sm-12">
              <div className="event-block_one-inner">
                <div className="event-block_one-image">
                  <a href="event-detail.html"><img style={{height: '15rem'}} src="assets/images/resource/thodiyur.jpeg" alt="" /></a>
                </div>
                <div className="event-block_one-content">
                <div className="event-block_one-time">3 Dec 2023</div>
                  {/* <div className="event-block_one-location"><i className="fa-solid fa-location-dot fa-fw" /> Fiolveien 230, Norway</div> */}
                  <h5 className="event-block_one-heading"><Link to="/inaugurations">Inauguration of scholars mail services by Thodiyur Muhammed Kunju Maulavi (Former Wakf Board Chairman)</Link></h5>
                  {/* <div className="event-block_one-price d-flex justify-content-between align-items-center flex-wrap">
                    Upcoming Event Price <span>$12.00</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Style */}
      <footer className="main-footer">
        <div
          className="footer_bg-image"
          style={{
            backgroundImage: "url(assets/images/background/footer-bg.jpg)",
          }}
        />
        <div className="auto-container">
          <div className="inner-container">
            {/* Widgets Section */}
            <div className="widgets-section">
              <div className="row clearfix">
                {/* Big Column */}
                <div className="big-column col-lg-6 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/* Footer Column */}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget logo-widget">
                        <div className="footer-logo">
                          <Link to="/" style={{ display: "flex" }}>
                            {/* <img src="assets/images/footer-logo.svg" alt="" /> */}
                            <img src="assets/images/logo-n-1.png" alt="" />
                            <h3
                              style={{
                                color: "white",
                                fontWeight: "bold",
                                fontFamily: "lexend",
                              }}
                            >
                              Masjid
                            </h3>
                          </Link>
                        </div>
                        {/* Footer List */}
                        <div className="footer_list">
                          <li>
                            58 Howard Street #2 cairo. <br /> CA 941
                          </li>
                          <li>(+88)311-2121101</li>
                        </div>
                        {/* Social Box */}
                        <div className="footer_socials">
                          <a href="https://facebook.com/">
                            <i className="fa-brands fa-facebook-f" />
                          </a>
                          <a href="https://twitter.com/">
                            <i className="fa-brands fa-twitter" />
                          </a>
                          <a href="https://youtube.com/">
                            <i className="fa-brands fa-youtube" />
                          </a>
                          <a href="https://instagram.com/">
                            <i className="fa-brands fa-instagram" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget links-widget">
                        <h4 className="footer-title">Links</h4>
                        <ul className="footer-list">
                          <li>
                            <a href="#">About us</a>
                          </li>
                          <li>
                            <a href="#">Scholars</a>
                          </li>
                          <li>
                            <a href="#">Courses</a>
                          </li>
                          <li>
                            <a href="#">Latest News</a>
                          </li>
                          <li>
                            <a href="#">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Big Column */}
                <div className="big-column col-lg-6 col-md-12 col-sm-12">
                  <div className="row clearfix">
                    {/* Footer Column */}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget links-widget">
                        <h4 className="footer-title">Other Link</h4>
                        <ul className="footer-list">
                          <li>
                            <a href="#">Online Courses</a>
                          </li>
                          <li>
                            <a href="#">Audio Listening</a>
                          </li>
                          <li>
                            <a href="#">Sehri &amp; Iftar</a>
                          </li>
                          <li>
                            <a href="#">Our Events</a>
                          </li>
                          <li>
                            <a href="#">Quran Hifz Classes</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                      <div className="footer-widget newsletter-widget">
                        <h4 className="footer-title">newsletter</h4>
                        <div className="footer-text">
                          Waiting for your message is not your important time
                        </div>
                        <div className="newsletter-box">
                          <form
                            method="post"
                            action="https://themazine.com/html/alquran/contact.html"
                          >
                            <div className="form-group">
                              <input
                                type="email"
                                name="search-field"
                                placeholder="email address"
                                required
                              />
                              <button
                                type="submit"
                                className="theme-btn fa-regular fa-paper-plane fa-fw"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div
            className="footer_bottom-bg"
            style={{
              backgroundImage: "url(assets/images/background/footer-bg_2.jpg)",
            }}
          />
          <div className="auto-container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="copyright">
                All rights reserved 2023 © template_mr
              </div>
              <ul className="footer-nav">
                <li>
                  <a href="#">Terms of use</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* End Footer Style */}
      {/* Search Popup */}
      <div className="search-popup">
        <div className="color-layer" />
        <button className="close-search">
          <span className="flaticon-close-1" />
        </button>
        <form
          method="post"
          action="https://themazine.com/html/alquran/blog.html"
        >
          <div className="form-group">
            <input
              type="search"
              name="search-field"
              placeholder="Search Here"
              required
            />
            <button
              className="fa fa-solid fa-magnifying-glass fa-fw"
              type="submit"
            />
          </div>
        </form>
      </div>
      {/* End Search Popup */}
    </div>
  );
}

export default Inaugurations;
