import React from 'react'

function ScholarCard({data}) {
  console.log("🚀 ~ ScholarCard ~ data:", data)
  return (
    <div className="scholar-block_one col-lg-4 col-md-6 col-sm-12">
              <div className="scholar-block_one-inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1000ms">
                <div className="scholar-block_one-image">
                  <a href="scholar-detail.html"><img src={`${data?.photo}`} alt="370x433" /></a>
                </div>
                <div className="scholar-block_one-content">
                  <h4 className="scholar-block_one-heading"><a href="scholar-detail.html">{data?.name}</a></h4>
                  <div className="scholar-block_one-designation">{data?.district}, {data?.state}</div>
                  {/* Social Box */}
                  <div className="scholar-block_one-socials">
                    <a href="https://facebook.com/"><i className="fa-brands fa-facebook-f" /></a>
                    <a href="https://instagram.com/"><i className="fa-brands fa-instagram" /></a>
                    <a href="https://twitter.com/"><i className="fa-brands fa-twitter" /></a>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default ScholarCard