import React from 'react';
import './AutoScrollBanner.css';

interface AutoScrollBannerProps {
    notice: string; // Adjust the type according to your needs
}

const AutoScrollBanner: React.FC<AutoScrollBannerProps> = ({ notice }) => {
  return (
    <div className="auto-scroll-banner">
      <div className="content">{notice}</div>
    </div>
  );
};

export default AutoScrollBanner;
