import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
// import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { fetchToken, onMessageListener, subscribeToTopic } from "./firebase/firebase";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import Home from "./pages/home/Home";
import MosquesList from "./pages/mosques-list/MosquesList";
import Inaugurations from "./pages/events/Inaugurations";
import JamaathDetail from "./pages/jamaath/jamaath-detail/JamaathDetail";
import MasjidDetail from "./pages/mosques-list/MosqueDetail";
import NotificationBox from "./components/notification/NotificationBox";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/mosques",
    element: <MosquesList />,
  },
  {
    path: "/inaugurations",
    element: <Inaugurations />,
  },
  {
    path: "/jamaath/:id",
    element: <JamaathDetail />,
  },
  {
    path: "/masjid/:id",
    element: <MasjidDetail />,
  },
]);

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState();

  fetchToken(setTokenFound, setToken);

  // onMessageListener().then(payload => {
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   setShow(true);
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({
      title: "Notification",
      body: "This is a test notification",
    });
    setShow(true);
  };

  // useEffect(() => {
  //   subscribeToTopic('users')
  // }, [])

  useEffect(() => {
    // Check if the browserId is already stored in localStorage
    let browserId = localStorage.getItem('imbrowserId');

    // If not, generate a new one and store it
    if (!browserId) {
      browserId = uuidv4();
      localStorage.setItem('imbrowserId', browserId as string);
    }

    // Assuming you have a function to send data to the backend
    sendBrowserIdToBackend(browserId);
  }, [token]);

  const sendBrowserIdToBackend = async (browserId: any) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASEURL}/fcmTokens`, {
        token: token,
        device: 'WEB',
        deviceId: browserId,
        userType: 'USER'
      });
    } catch (error) {
      console.log('e',error)
    }
  };
  

  onMessageListener()
    .then((payload: any) => {
      // setNotification({title: payload.notification.title, body: payload.notification.body})
      // setShow(true);
      console.log(payload);
      toast(<NotificationBox notification={payload.notification}/>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      });
    })
    .catch((err: any) => console.log("failed: ", err));

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </>
  );
}

export default App;
