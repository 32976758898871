import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Bounce, toast, ToastContainer } from "react-toastify";

import "./Header.css";
import { Link } from "react-router-dom";
import { onMessageListener } from "../../../firebase/firebase";

function Header() {
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState("En");

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng === "en" ? "En" : "Ar");
  };

  const handleToggleMobileMenu = (open: boolean) => {
    if (open === true) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  };

  

  return (
    <header className="main-header">
      {/* Header Top */}
      <div className="header-top">
        <div className="auto-container">
          <div className="inner-container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="left-box d-flex align-items-center flex-wrap">
                {/* Info List */}
                <ul className="header-top_list">
                  <li>
                    <span className="icon fa-solid fa-envelope fa-fw" />
                    Support24@gmail.com
                  </li>
                </ul>
                <div className="bismillah">
                  <img src="assets/images/icons/bismillah.png" alt="" />{" "}
                </div>
              </div>
              <ul className="header-top_list-two">
                <li>
                  <span className="icon fa-regular fa-sun fa-fw" />
                  Sunrise At: 6:15 AM
                </li>
                <li>
                  <span className="icon fa-solid fa-moon fa-fw" />
                  Sunset At: 6:50 PM
                </li>
                <li>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={currentLanguage}
                    className="custom-dropdown-button"
                  >
                    <Dropdown.Item onClick={() => changeLanguage("en")}>
                      En
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("ar")}>
                      Ar
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => changeLanguage("ml")}>
                      Mal
                    </Dropdown.Item> */}
                  </DropdownButton>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Header Upper */}
      <div className="header-upper">
        <div className="auto-container">
          <div className="inner-container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/" style={{ display: "flex" }}>
                    {/* <img src="assets/images/logo.svg" alt="" /> */}
                    {/* <h3 style={{ color: '#509d4b', fontWeight: 'bold' }}>Indian</h3> */}
                    <img
                      src="assets/images/logo-n-1.png"
                      alt=""
                      onError={(e: any) => {
                        e.target.src = "../assets/images/logo-n-1.png";
                        e.target.alt = "";
                      }}
                    />
                    <h3
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        fontFamily: "lexend",
                      }}
                    >
                      Masjid
                    </h3>

                    {/* <img src="assets/images/logo-new-1.png" alt="" /> */}
                  </Link>
                </div>
              </div>
              <div className="nav-outer">
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-md">
                  <div className="navbar-header">
                    {/* Toggle Button */}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div
                    className="navbar-collapse collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="dropdown">
                        <Link to="/">{t("menu_home")}</Link>
                        {/* <ul>
                              <li><a href="index.html">Homepage 01</a></li>
                              <li><a href="index-2.html">Homepage 02</a></li>
                              <li><a href="index-3.html">Homepage 03</a></li>
                              <li className="dropdown"><a href="#">Header Styles</a>
                                <ul>
                                  <li><a href="index.html">Header 01</a></li>
                                  <li><a href="index-2.html">Header 02</a></li>
                                  <li><a href="index-3.html">Header 03</a></li>
                                </ul>
                              </li>
                            </ul> */}
                      </li>
                      <li className="">
                        <a>{t("menu_about")}</a>
                        <ul>
                          <li>
                            <a>About us</a>
                          </li>
                          <li>
                            <a>price</a>
                          </li>
                          <li className="dropdown">
                            <a>events</a>
                            <ul>
                              <li>
                                <a>events</a>
                              </li>
                              <li>
                                <a>Event Detail</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="dropdown">
                        <a href="#">Pages</a>
                        <ul>
                          <li>
                            <a href="faq.html">Faq's</a>
                          </li>
                          <li>
                            <a href="prayer-time.html">Prayer Time</a>
                          </li>
                          <li>
                            <a href="record-class.html">Record Class</a>
                          </li>
                          <li>
                            <a href="register.html">register</a>
                          </li>
                          <li>
                            <a href="not-found.html">Not Found</a>
                          </li>
                          <li className="dropdown">
                            <a href="#">scholars</a>
                            <ul>
                              <li>
                                <a href="scholars.html">scholars</a>
                              </li>
                              <li>
                                <a href="scholar-detail.html">scholar detail</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                      <li className="">
                        <Link to={"/inaugurations"}>
                          {t("menu_inauguration")}
                        </Link>
                      </li>
                      <li className="">
                        <Link to={"/"}>{t("menu_waqf")}</Link>
                      </li>
                      {/* <li className="dropdown">
                        <a href="#">courses</a>
                        <ul>
                          <li>
                            <a href="courses.html">courses</a>
                          </li>
                          <li>
                            <a href="course-detail.html">course detail</a>
                          </li>
                        </ul>
                      </li> */}
                      <li className="">
                        <a>{t("menu_blog")}</a>
                        <ul>
                          <li>
                            <a>Our Blog</a>
                          </li>
                          <li>
                            <a>Blog Classic</a>
                          </li>
                          <li>
                            <a>Blog Detail</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a>{t("menu_contact")}</a>
                      </li>

                      <li>
                        <Link to={"/register-complaint"}>{t("menu_complaint")}</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Main Menu End*/}
              <div className="outer-box d-flex align-items-center flex-wrap">
                {/* Search Btn */}
                {/* <div className="search-box-btn search-box-outer">
                  <span className="icon fa fa-search" />
                </div> */}
                {/* User Box */}
                <a className="user-box theme-btn">
                  <span className="fa-regular fa-user fa-fw" />
                </a>
                {/* Button Box */}
                {/* <div className="header_button-box">
                  <a href="contact.html" className="theme-btn btn-style-one">
                    <span className="btn-wrap">
                      <span className="text-one">Quick Join Now</span>
                      <span className="text-two">Quick Join Now</span>
                    </span>
                  </a>
                </div> */}
                {/* Mobile Navigation Toggler */}
                <div
                  className="mobile-nav-toggler"
                  onClick={() => handleToggleMobileMenu(true)}
                >
                  <span className="icon flaticon-menu" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
      {/* Mobile Menu  */}
      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div
          className="close-btn"
          onClick={() => handleToggleMobileMenu(false)}
        >
          <span className="icon flaticon-close-1" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a>
              <img src="assets/images/logo.svg" alt="" />
            </a>
          </div>
          <div className="menu-outer">
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
            <div className="navbar-header">
              {/* Toggle Button */}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
            </div>
            <div
              className="navbar-collapse collapse clearfix"
              id="navbarSupportedContent"
            >
              <ul className="navigation clearfix">
                <li className="dropdown">
                  <Link to="/">{t("menu_home")}</Link>
                </li>
                <li className="dropdown">
                  <a>{t("menu_about")}</a>
                  <ul style={{ display: "none" }}>
                    <li>
                      <a href="about.html">About us</a>
                    </li>
                    <li>
                      <a href="price.html">price</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">events</a>
                      <ul>
                        <li>
                          <a href="events.html">events</a>
                        </li>
                        <li>
                          <a href="event-detail.html">Event Detail</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to={"/inaugurations"}>{t("menu_inauguration")}</Link>
                </li>
                <li className="dropdown">
                  <Link to={"/"}>{t("menu_waqf")}</Link>
                </li>

                <li className="dropdown">
                  <a>{t("menu_blog")}</a>
                  <ul>
                    <li>
                      <a href="blog.html">Our Blog</a>
                    </li>
                    <li>
                      <a href="blog-classic.html">Blog Classic</a>
                    </li>
                    <li>
                      <a href="news-detail.html">Blog Detail</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={"/register-complaint"}>{t("menu_complaint")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* End Mobile Menu */}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </header>
  );
}

export default Header;
